import tr from '@/locale/tr.json';
import en from '@/locale/en.json';
import de from '@/locale/de.json';
import ru from '@/locale/ru.json';
import ar from '@/locale/ar.json';
import ja from '@/locale/ja.json';
import fr from '@/locale/fr.json';
import kk from '@/locale/kk.json';
import az from '@/locale/az.json';
import cs from '@/locale/cs.json';
import he from '@/locale/he.json';
import id from '@/locale/id.json';
import it from '@/locale/it.json';
import ms from '@/locale/ms.json';
import nl from '@/locale/nl.json';
import no from '@/locale/no.json';
import pl from '@/locale/pl.json';
import pt from '@/locale/pt.json';
import pt_br from '@/locale/pt_br.json';
import ro from '@/locale/ro.json';
import sv from '@/locale/sv.json';
import th from '@/locale/th.json';
import tl from '@/locale/tl.json';
import uk from '@/locale/uk.json';
import ur from '@/locale/ur.json';
import vi from '@/locale/vi.json';
import zh from '@/locale/zh.json';
import zh_tw from '@/locale/zh_tw.json';
import da from '@/locale/da.json';
import hi from '@/locale/hi.json';
import hu from '@/locale/hu.json';
import es from '@/locale/es.json';
import ko from '@/locale/ko.json';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    tr, en, de, ru, ar, ja, fr, kk, az, cs,
    he, id, it, ms, nl, no, pl, pt, pt_br, ro,
    sv, th, tl, uk, ur, vi, zh, zh_tw, da, hi, hu, es, ko
  }
}));
